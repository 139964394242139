<template>
  <vs-dialog
  scroll 
  overflow-hidden 
  not-close 
  auto-width 
  v-model="$store.state.card.isModalOpen"
  @close="closeModal"
  :loading="isModalLoading"
  >
    <template v-if="!isModalLoading">

      <h1 class="cardTitle">
        {{ details.title }} 
      </h1>

      <div class="cardContent" v-html="details.content"></div>

      <aside>
        <vs-button
          color="primary"
          icon
          size="xl"
          border
          class="containerApplaudIcon"
          :class="applauded ? 'active' : ''"
          :active="applauded"
          @click="applaud"
        >
          <PartyPopper class="applaudIcon"/>
        </vs-button>
      </aside>

    </template>

    <template v-else>
      <div class="fakeContentSize"></div>
    </template>

  </vs-dialog>
</template>

<script>
  import confetti from 'canvas-confetti'
  import PartyPopper from '@/components/icons/PartyPopper.vue'
  import NotificationForLogin from '@/components/NotificationForLogin.vue'

  export default {
    name: 'CardDetails',
    components: {
      PartyPopper,
    },
    data() {
      return {
        applauded: false,
        noMoreAnimation: false
      }
    },
    computed: {
      details() {
        let newDetails = this.$store.state.card.modalCard
        this.applauded = (newDetails.applauded)
        return newDetails
      },
      isModalLoading() {
        return this.$store.state.card.isModalLoading
      }
    },
    methods: {
      closeModal() {
        this.$store.dispatch('card/closeModal')
        this.$router.push({ query: Object.assign({}, this.$route.query, { card: undefined }) });
      },
      async applaud(event) {
        if (!this.applauded) {

          if (await this.$store.getters['user/isAuthenticated']) {
            
            // Animation de l'applaudissement
            this.applauded = true
            confetti({
              particleCount: 100,
              zIndex: 99000,
              origin: {
                x: (event.x/event.view.innerWidth),            
                y: (event.y/event.view.innerHeight)
              }
            })
            
            // Enregistrement de l'applaudissement
            const error = await this.$store.dispatch('user/applaud', { cardID: this.details.id })
            if (error !== null) {
              this.applauded = false
              this.$store.dispatch('notification/error', `L'enregistrement de votre applaudissement n'a hélas pas fonctionné (erreur côté serveur)`)
            }
          } else {
            // Utilisateur non-connecté
            this.$store.dispatch('notification/custom', {
              content: NotificationForLogin,
              duration: 6000,
              progress: 'auto',
              position: 'bottom-right',
              clickClose: true,
              buttonClose: false,
              onClick: () => { 
                this.$store.dispatch('user/openModal', 'register')
              }
            })
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.cardTitle {
  text-align: center;
  margin: 20px auto 30px;
}
aside {
  margin: 20px auto 30px;
}
.containerApplaudIcon { 
  margin: auto;
  padding: 20px;
  &.active { 
    .applaudIcon { fill: #fff; }
    &:focus {
      background: rgba(var(--vs-color), 1);
    }
  }
  &:focus {
    background: rgba(var(--vs-color), 0);
  }
}
.applaudIcon {
  width: 50px;
  height: 50px;
  fill: rgb(var(--vs-color));
}
.fakeContentSize {
  height: calc(100vh - 200px);
  width: 800px;
}
</style>

<style lang="scss">

.vs-dialog {
  border-radius: 10px !important;
  &.vs-dialog--loading .vs-dialog__content { overflow: hidden; }
  .vs-dialog__content { 
    max-height: calc(100vh - 200px) !important;
    margin: 0 !important;
    padding: 2rem 4rem !important;
  }
}

pre {
  overflow: auto;
  overflow-wrap: break-word;
  padding: 1rem;
  margin: 1rem auto;
  white-space: pre-wrap;
}

</style>