export const ModalWithUserForm = {
  data () {
    return {
      active: false,
      loading: false,
      errorsCodes: [],
      remember: false
    }
  },
  computed: {
    currentOpenedUserModal () {
      return this.$store.state.user.currentOpenedModal
    },
    errorsSentences () {
      const sentences = []
      for (let i = 0; i < this.errorsCodes.length; i++) {
        const code = this.errorsCodes[i]
        if (this.$store.state.errors[code]) {
          sentences.push(this.$store.state.errors[code])
        } else {
          sentences.push(`Erreur inconnue: ${code}`)
        }
      }
      return sentences
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('user/closeModal')
      this.errorsCodes = []
      this.resetFields()
    },
    changeUserModal (destination) {
      this.$store.dispatch('user/openModal', destination)
    },
    async submit () {
      // Vérifications des valeurs
      this.errorsCodes = []
      this.checkFields()
      // Tentative de connexion
      if (this.errorsCodes.length === 0) {
        this.loading = true
        const error = await this.$store.dispatch('user/call', {
          path: this.methodToCallOnSubmit,
          fields: this.fields
        })
        this.loading = false
        if (typeof error === 'string') {
          this.errorsCodes.push(error)
        } else {
          this.closeModal()
          this.$store.dispatch('notification/success', 'Vous êtes connecté !')
        }
      }
    }
  }
}
