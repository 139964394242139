<template>
  <div>
    <h2 class="title">Rejoignez-nous</h2>
    <p class="text">Profiter de toutes les fonctionnalités de DevThery Cards avec un compte 💯</p>
    <vs-button block size="large">Applaudir des cards</vs-button>
  </div>
</template>

<script>
  export default {
    name: "NotificationForLogin"
  }
</script>

<style lang="scss" scoped>
div {
  text-align: center;
}
.title {
  margin-top: 10px;
}
.text {
  line-height: 1.5
}
</style>