import UserService from '@/services/UserService.js'

export default {
  namespaced: true,
  state: {
    token: null,
    email: null,
    currentOpenedModal: null
  },
  mutations: {
    SET_TOKEN (state, value) {
      state.token = value
    },
    SET_EMAIL (state, value) {
      state.email = value
    },
    SET_CURRENT_OPENNED_MODAL (state, currentOpenedModal) {
      state.currentOpenedModal = currentOpenedModal
    }
  },
  actions: {

    // Gestion des valeurs-utilisateur du state
    async setStateUserData ({ state, commit, dispatch }) {
      // Liaison des éventuelles données en storage avec le store
      commit('SET_TOKEN', await dispatch('getInStorage', 'token') || null)
      commit('SET_EMAIL', await dispatch('getInStorage', 'email') || null)

      // Connexion avec la base de données
      if (state.token) {
        await dispatch('fauna/setUserConnection', {
          token: state.token,
          email: state.email
        }, { root: true })
      } else {
        dispatch('fauna/setBootstrapConnection', null, { root: true })
      }
    },
    clearUserData ({ commit, dispatch }) {
      commit('SET_TOKEN', null)
      commit('SET_EMAIL', null)
      dispatch('resetStorage')
      dispatch('fauna/setBootstrapConnection', null, { root: true })
    },

    // Méthodes pour l'ouverture des modals liés au système d'utilisateurs
    openModal ({ commit }, name) {
      commit('SET_CURRENT_OPENNED_MODAL', name)
    },
    closeModal ({ commit }) {
      commit('SET_CURRENT_OPENNED_MODAL', null)
    },

    // Getter & Setter & Reset des valeurs en storage (session ou local)
    getInStorage (ctx, key) {
      let data = window.localStorage.getItem(key)
      if (!data) {
        data = window.sessionStorage.getItem(key)
      }
      return data
    },
    setInStorage (ctx, { key, value, inLocalStorage }) {
      if (inLocalStorage) {
        window.localStorage.setItem(key, value)
      } else {
        window.sessionStorage.setItem(key, value)
      }
    },
    resetStorage () {
      window.sessionStorage.clear()
      window.localStorage.clear()
    },

    // Connexion & Inscription
    async call ({ dispatch }, { path, fields }) {
      const taskID = await dispatch('progressbar/addTask', 'fetchAll', { root: true })
      let errorToReturn = false
      try {
        const res = await UserService.call(path, fields)
        if (res.data.status === 'success') {
          await dispatch('setInStorage', { key: 'token', value: res.data.result.token, inLocalStorage: fields.rememberMe })
          await dispatch('setInStorage', { key: 'email', value: fields.email, inLocalStorage: fields.rememberMe })
          await dispatch('setStateUserData')
        } else {
          errorToReturn = res.data.message
        }
      } catch (e) {
        errorToReturn = e.message
        console.error(e)
      }
      dispatch('progressbar/doneTask', taskID, { root: true })
      return errorToReturn
    },

    // Déconnexion
    async logout ({ dispatch }) {
      const taskID = await dispatch('progressbar/addTask', 'fetchAll', { root: true })
      let errorToReturn = false
      try {
        await dispatch('fauna/logout', false, { root: true })
      } catch (e) {
        errorToReturn = e.message
        console.error(e)
      }
      dispatch('clearUserData')
      dispatch('progressbar/doneTask', taskID, { root: true })
      return errorToReturn
    },

    // Oublie du mot de passe
    async forgot ({ dispatch }, { email }) {
      const taskID = await dispatch('progressbar/addTask', 'fetchAll', { root: true })
      const arrayToReturn = ['error', null]
      try {
        const res = await UserService.forgot(email)
        if (res.data.status === 'success') {
          arrayToReturn[0] = 'success'
          arrayToReturn[1] = res.data.result.code
        } else {
          arrayToReturn[1] = res.data.message
        }
      } catch (e) {
        arrayToReturn[1] = e.message
        console.error(e)
      }
      dispatch('progressbar/doneTask', taskID, { root: true })
      return arrayToReturn
    },

    // Reset du mot de passe
    async reset ({ dispatch }, { code, password, token }) {
      const taskID = await dispatch('progressbar/addTask', 'fetchAll', { root: true })
      let errorToReturn = false
      try {
        const res = await UserService.reset(code, password, token)
        if (res.data.status !== 'success') {
          errorToReturn = res.data.message
        }
      } catch (e) {
        errorToReturn = e.message
        console.error(e)
      }
      dispatch('progressbar/doneTask', taskID, { root: true })
      return errorToReturn
    },

    // Aplaudissement d'une card
    async applaud ({ rootState }, { cardID }) {
      let errorToReturn = null
      try {
        const client = rootState.fauna.client
        const res = await UserService.applaud({ cardID, client })
        if (res.data.status !== 'success') {
          errorToReturn = res.data.message
          console.error(new Error(errorToReturn))
        }
      } catch (e) {
        errorToReturn = e.message
        console.error(e)
      }
      return errorToReturn
    }

  },
  getters: {
    isAuthenticated (state) {
      return !!(state.token)
    }
  }
}
