import Vue from 'vue'
import Vuex from 'vuex'
import fauna from '@/store/modules/fauna.js'
import card from '@/store/modules/card.js'
import user from '@/store/modules/user.js'
import notification from '@/store/modules/notification.js'
import progressbar from '@/store/modules/progressbar.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categories: [
      {
        name: 'Vuejs',
        icon: 'bxl-vuejs',
        path: '/category/vue'
      },
      {
        name: 'React',
        icon: 'bxl-react',
        path: '/category/react'
      },
      {
        name: 'Angular',
        icon: 'bxl-angular',
        path: '/category/angular'
      },
      {
        name: 'Nodejs',
        icon: 'bxl-nodejs',
        path: '/category/node'
      },
      {
        name: 'JavaScript',
        icon: 'bxl-javascript',
        path: '/category/javascript'
      }
    ],
    errors: {
      main_catch: 'Une erreur inconnue a eu lieu côté serveur',
      wrong_method: 'La méthode HTTP utilisée pour la requête n\'est pas la bonne',
      bad_query: 'La requête en base de données a échoué',
      wrong_email_format: "Le format de l'email entré n'est pas valide",
      email_used: "L'email entré est déjà utilisé par un utilisateur",
      wrong_password_length: 'La taille du mot de passe ne peut pas être inférieur à 4 caractères',
      login_failed: 'La connexion avec ces identifiants a échoué',
      token_not_valid: "Votre token d'authentification n'est plus valide",
      logout_failed: 'La déconnexion a échoué (une erreur surement impossible)',
      empty_field: 'Un des champs nécessaire du formulaire est vide',
      passwords_dont_match: 'Les mots de passe indiqués ne sont pas identiques',
      mail_failed: "L'envoie du mail a échoué",
      too_late: 'Le délai maximum a été dépassé',
      too_late_10m: 'Le délai maximum de 10 minutes a été dépassé',
      bad_code: "Le code indiqué n'est pas celui attendu",
      token_not_found: "Le token unique permettant votre authentification n'existe pas"
    }
  },
  modules: {
    fauna,
    card,
    user,
    notification,
    progressbar
  }
})
