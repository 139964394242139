<template>
  <div class="home">
    <CardsList :loadingState="areCardsLoading" :cardsPerPage="perPage"/>
    <div class="center">
      <div v-if="isPossibleToGoBefore || isPossibleToGoAfter" class="vs-pagination-content vs-component--primary">
        <button
          class="vs-pagination__arrow prev"
          :disabled="(isPossibleToGoBefore && !areCardsLoading) ? false : true"
          @click="goToPreviousPage"
          >
          <i class="vs-icon-arrow"></i>
        </button>
        <div class="vs-pagination__slot"> {{ page }} </div>
        <button 
          class="vs-pagination__arrow next"
          :disabled="(isPossibleToGoAfter && !areCardsLoading) ? false : true"
          @click="goToNextPage"
          >
          <i class="vs-icon-arrow"></i>
        </button>
      </div>
      <!-- <vs-pagination only-arrows v-model="page" :length="maxPage">
        {{ page }}    
      </vs-pagination>  -->
      <!-- <vs-pagination v-show="maxPage > 1" v-model="page" :length="maxPage"/> -->
    </div>
  </div>
</template>

<script>
import CardsList from '@/components/CardsList.vue'
import { ViewsWithCardsList } from '@/mixins/ViewsWithCardsList.js'

export default {
  name: 'Home',
  mixins: [ViewsWithCardsList],
  components: {
    CardsList
  }
}
</script>
