import fauna from 'faunadb'
const q = fauna.query
const bootstrapToken = process.env.FAUNA_BOOTSTRAP_SECRET || 'fnAEClLwZ4ACB85VrQ24VI7vZVAKjS0nud9wxnIq'

export default {
  namespaced: true,
  state: {
    client: null
  },
  mutations: {
    SET_CLIENT (state, value) {
      state.client = value
    }
  },
  actions: {

    // Connexion d'un token utilisateur à Fauna (avec vérification)
    async setUserConnection ({ commit, dispatch }, { token, email }) {
      try {
        const client = new fauna.Client({ secret: token })
        await client.query(q.Get(q.Match(q.Index('Users_search_by_email'), email)))
        commit('SET_CLIENT', client)
        dispatch('manageCurrentCardAfterReset')
      } catch (e) {
        if (e.requestResult && e.requestResult.responseContent.errors[0].code === 'permission denied') {
          await dispatch('user/clearUserData')
          await dispatch('notification/error', 'Votre authentification ne fonctionnant plus (expiration naturelle), vous avez été déconnecté.', { root: true })
        } else {
          await dispatch('notification/error', 'Une erreur a eu lieu entre votre authentification et la base de données.', { root: true })
        }
        console.log(e)
      }
    },

    // Déconnexion du token utilisateur
    logout ({ state }, everywhere = false) {
      return state.client.query(q.Logout(everywhere))
    },

    // Connexion à Fauna via le token commun
    setBootstrapConnection ({ commit, dispatch }) {
      const client = new fauna.Client({ secret: bootstrapToken })
      commit('SET_CLIENT', client)
      dispatch('manageCurrentCardAfterReset')
    },

    // Système pour éventuellement recharger ou supprimer la card existante
    manageCurrentCardAfterReset ({ rootState, dispatch }) {
      if (rootState.card.modalCard.id && rootState.card.isModalOpen) {
        dispatch('card/openModal', {
          id: rootState.card.modalCard.id,
          forceReload: true
        }, { root: true })
      } else {
        dispatch('card/removeLastModalCardData', null, { root: true })
      }
    }

  }
}
