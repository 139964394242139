<template>
  <vs-dialog
  v-model="active"
  @close="closeModal"
  not-close
  :loading="loading"
  class="userProfileModal"
  >    
    <template #header>
      <h4 class="not-margin">
        Bienvenue sur <b>DevTheory Cards</b>
      </h4>
    </template>


    <form class="con-form" @submit.prevent="submit">

      <vs-input type="email" v-model="fields.email" placeholder="Email" autocomplete="email">
        <template #icon>
          @
        </template>
      </vs-input>

      <vs-input type="password" v-model="fields.password" placeholder="Mot de passe" autocomplete="current-password">
        <template #icon>
          <i class='bx bxs-lock'></i>
        </template>
      </vs-input>

      <div class="flex">
        <vs-checkbox v-model="fields.rememberMe">Rester connecté</vs-checkbox>
        <a href="#" @click="changeUserModal('passwordforgot')">Mot de passe oublié ?</a>
      </div>

      <vs-alert v-if="errorsSentences.length > 0" :key="errorsCodes.join()">

        <template #title>
          {{ errorsSentences.length >= 2 ? 'Erreurs' : 'Erreur' }} de connexion
        </template>
        
        <ul v-if="errorsSentences.length >= 2" style="margin: 0">
          <li v-for="(sentence, index) in errorsSentences" :key="index">{{ sentence }}</li>
        </ul>

        <span v-else>{{ errorsSentences[0] }} </span>

      </vs-alert>

    </form>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block @click="submit">
          Se connecter
        </vs-button>

        <div class="new">
          Nouveau ?<a href="#" @click="changeUserModal('register')">Créer votre compte DevTheory</a>
        </div>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
  import { ModalWithUserForm } from '@/mixins/ModalWithUserForm.js'
  import { validate as validateEmail } from 'email-validator'

  export default {
    name: 'Login',
    mixins: [ModalWithUserForm],
    data() {
      return {
        fields: {
          email: '',
          password: '',
          rememberMe: true
        },
        methodToCallOnSubmit: 'login'
      }
    },
    watch: {
      currentOpenedUserModal(newVal) {
        this.active = (newVal === 'login')
      }
    },
    methods: {
      checkFields() {
        if (this.fields.email == '' || this.fields.password == '') {
          this.errorsCodes.push('empty_field')
        }
        if (this.fields.email !== '' && !validateEmail(this.fields.email)) {
          this.errorsCodes.push('wrong_email_format')
        }
      },
      resetFields() {
        this.fields.email = ''
        this.fields.password = ''
      }
    }
  }
</script>

<style lang="scss">
.not-margin {
	margin: 0px;
	font-weight: normal;
	padding: 10px;
}

.con-form {
	width: 100%;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 0.8rem;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }

  .vs-checkbox-label {
    font-size: 0.8rem;
  }

  .vs-input-content {
    margin: 10px 0px;
    width: calc(100%);
    .vs-input {
      width: 100% !important;
    }
  }

}

.footer-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: calc(100%);

  .new {
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-size: 0.7rem;
    a {
      color: getColor('primary') !important;
      margin-left: 6px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .vs-button {
    margin: 0px;
  }


}
</style>