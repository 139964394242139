import { query } from 'faunadb'
const q = query

export default {
  getCards (perPage, category, direction, afterPointer, beforePointer, client) {
    let queryToExecute = null
    if (category) {
      if (direction === 'before') {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsByCategoryWithBefore'), [category, perPage, beforePointer])
      } else if (direction === 'after') {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsByCategoryWithAfter'), [category, perPage, afterPointer])
      } else {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsByCategoryWithLimit'), [category, perPage])
      }
    } else {
      if (direction === 'before') {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsWithBefore'), [perPage, beforePointer])
      } else if (direction === 'after') {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsWithAfter'), [perPage, afterPointer])
      } else {
        queryToExecute = q.Call(q.Function('GetLastPublishedCardsWithLimit'), perPage)
      }
    }
    return client.query(
      q.Map(
        queryToExecute,
        q.Lambda(['publishedTime', 'cardRef'], q.Let({
          cardDoc: q.Get(q.Var('cardRef')),
          categoryDoc: q.Get(q.Select(['data', 'categoryRef'], q.Var('cardDoc')))
        }, {
          id: q.Select(['ref', 'id'], q.Var('cardDoc')),
          publishedDate: q.ToString(q.ToDate(q.Var('publishedTime'))),
          title: q.Select(['data', 'title'], q.Var('cardDoc')),
          applauses: q.Select(['data', 'applauses'], q.Var('cardDoc')),
          category: q.Select(['data', 'name'], q.Var('categoryDoc'))
        }))
      )
    )
  },
  getCard (id, client) {
    return client.query(
      q.Let({
        cardRef: q.Ref(q.Collection('Cards'), id),
        cardDoc: q.Get(q.Var('cardRef')),
        categoryDoc: q.Get(q.Select(['data', 'categoryRef'], q.Var('cardDoc'))),
        didUserApplaud: q.If(
          q.HasCurrentIdentity(),
          q.Call(q.Function('DoesApplaudExistByCardAndUserRefs'), [q.Var('cardRef'), q.CurrentIdentity()]),
          false
        )
      }, {
        id: q.Select(['ref', 'id'], q.Var('cardDoc')),
        content: q.Select(['data', 'content'], q.Var('cardDoc')),
        publishedDate: q.ToString(q.ToDate(q.Select(['data', 'publishedTime'], q.Var('cardDoc')))),
        title: q.Select(['data', 'title'], q.Var('cardDoc')),
        applauses: q.Select(['data', 'applauses'], q.Var('cardDoc')),
        category: q.Select(['data', 'name'], q.Var('categoryDoc')),
        applauded: q.Var('didUserApplaud')
      })
    )
  }
}
