import CardService from '@/services/CardService.js'

export default {
  namespaced: true,
  state: {
    cards: [],
    modalCard: {},
    isModalOpen: false,
    isModalLoading: false,
    currentTaskID: null,
    afterPointer: null,
    beforePointer: null
  },
  mutations: {
    SET_CARDS (state, cards) {
      state.cards = cards
    },
    SET_MODAL_CARD (state, modalCard) {
      state.modalCard = modalCard
    },
    SET_IS_MODAL_LOADING (state, isLoading) {
      state.isModalLoading = isLoading
    },
    SET_IS_MODAL_OPEN (state, toShow) {
      state.isModalOpen = toShow
    },
    SET_CURRENT_TASK_ID (state, taskID) {
      state.currentTaskID = taskID
    },
    SET_AFTER_POINTER (state, afterPointer) {
      state.afterPointer = afterPointer
    },
    SET_BEFORE_POINTER (state, beforePointer) {
      state.beforePointer = beforePointer
    },
    RESET_CARDS (state) {
      state.cards = []
      state.cardsTotal = 0
    }
  },
  actions: {

    async openModal ({ state, commit, dispatch }, { id, forceReload }) {
      if (String(state.modalCard.id) === String(id) && forceReload === undefined) {
        commit('SET_IS_MODAL_OPEN', true)
      } else {
        // Ouverture du modal en mode loading
        const taskID = await dispatch('progressbar/addTask', 'fetchById', { root: true })
        commit('SET_CURRENT_TASK_ID', taskID)
        commit('SET_IS_MODAL_LOADING', true)
        commit('SET_IS_MODAL_OPEN', true)

        // Récupération du contenu de la card
        const isContentReceived = await dispatch('fetchById', { id, taskID })

        // Traitement uniquement si la tâche est toujours la même
        if (state.currentTaskID === taskID) {
          // Fin du mode loading
          dispatch('progressbar/doneTask', taskID, { root: true })
          commit('SET_CURRENT_TASK_ID', null)
          commit('SET_IS_MODAL_LOADING', false)
          if (isContentReceived !== true) {
            commit('SET_IS_MODAL_OPEN', false)
          }
        }
      }
    },

    closeModal ({ state, commit, dispatch }) {
      // Fermeture du modal
      commit('SET_IS_MODAL_OPEN', false)

      // Annulation du mode loading du modal si besoin
      if (state.isModalLoading) {
        dispatch('progressbar/doneTask', state.currentTaskID, { root: true })
        commit('SET_CURRENT_TASK_ID', null)
        commit('SET_IS_MODAL_LOADING', false)
      }
    },

    resetAll ({ commit }) {
      commit('RESET_CARDS')
    },

    async fetchAll ({ state, rootState, commit, dispatch }, { perPage, category, direction }) {
      const taskID = await dispatch('progressbar/addTask', 'fetchAll', { root: true })
      try {
        const client = rootState.fauna.client
        const res = await CardService.getCards(perPage, category, direction, state.afterPointer, state.beforePointer, client)
        commit('SET_CARDS', res.data)
        commit('SET_AFTER_POINTER', res.after || null)
        commit('SET_BEFORE_POINTER', res.before || null)
      } catch (err) {
        dispatch('notification/error', `Le réception des cards a échoué (${err.message})`, { root: true })
        console.error(err)
      }
      dispatch('progressbar/doneTask', taskID, { root: true })
    },

    async fetchById ({ state, rootState, commit, dispatch }, { id, taskID }) {
      let isContentReceived = null
      try {
        // Récupération du contenu de la card
        const client = rootState.fauna.client
        const res = await CardService.getCard(id, client)

        // Mise en place de la card si aucune annulation n'a été faite
        if (taskID === state.currentTaskID) {
          commit('SET_MODAL_CARD', res)
          isContentReceived = true
        }
      } catch (err) {
        dispatch('notification/error', `Le réception du contenu de la card a échoué (${err.message})`, { root: true })
        console.error(err)
        isContentReceived = false
      }
      return isContentReceived
    },

    // Supression des données de la dernière card
    removeLastModalCardData ({ state, commit }) {
      if (!state.isModalOpen) {
        commit('SET_MODAL_CARD', {})
      } else {
        console.error('Impossible d\'enlever les données de la card actuelle car le modal est ouvert')
      }
    }

  },
  getters: {
    areThereCardsAfter: state => {
      return Boolean(state.afterPointer)
    },
    getCardById: state => id => {
      return state.cards.find(card => card.id === id)
    }
  }
}
