import { v4 as uuid } from '@lukeed/uuid'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default {
  namespaced: true,
  state: {
    progressBar: NProgress,
    isActive: false,
    tasks: []
  },
  mutations: {
    SET_IS_ACTIVE (state, isActive) {
      state.isActive = isActive
    },
    ADD_TASK (state, newTask) {
      state.tasks.push(newTask)
    },
    DONE_TASK (state, taskIndex) {
      state.tasks[taskIndex].done = true
    }
  },
  actions: {

    addTask ({ state, getters, commit }, taskName) {
      // Lancement de la progress bar si c'est la nouvelle seule tâche en cours
      if (getters.activeTasks.length === 0) {
        state.progressBar.start()
        commit('SET_IS_ACTIVE', true)
      }
      // Ajout de cette tâche
      const task = {
        id: uuid(),
        name: taskName,
        done: false
      }
      commit('ADD_TASK', task)
      return task.id
    },

    doneTask ({ state, getters, commit }, taskID) {
      // Fin de la tâche
      const taskIndex = state.tasks.findIndex(task => task.id === taskID)
      commit('DONE_TASK', taskIndex)
      // Arrêt de la progress bar si c'était la dernière tâche en cours
      if (getters.activeTasks.length === 0) {
        state.progressBar.done()
        commit('SET_IS_ACTIVE', false)
      } else {
        state.progressBar.inc()
      }
    }

  },
  getters: {
    activeTasks (state) {
      return state.tasks.filter(task => !task.done)
    }
  }
}
