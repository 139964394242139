export default {
  namespaced: true,
  state: {
    notifications: [],
    position: 'top-right'
  },
  mutations: {
    PUSH_NOTIFICATION_IN_HISTORY (state, notification) {
      state.notifications.push(notification)
    }
  },
  actions: {

    success ({ state, commit }, text) {
      const notification = this._vm.$vs.notification({
        color: 'success',
        icon: '<i class=\'bx bx-select-multiple\'></i>',
        position: state.position,
        title: 'Succès !',
        text
      })
      commit('PUSH_NOTIFICATION_IN_HISTORY', notification)
    },

    error ({ state, commit }, text) {
      const notification = this._vm.$vs.notification({
        color: 'danger',
        icon: '<i class=\'bx bxs-bug\' ></i>',
        position: state.position,
        title: 'Erreur',
        text
      })
      commit('PUSH_NOTIFICATION_IN_HISTORY', notification)
    },

    soon ({ state, commit }) {
      const notification = this._vm.$vs.notification({
        color: 'warn',
        icon: '<i class=\'bx bx-error\' ></i>',
        position: state.position,
        title: 'Pas encore !',
        text: 'Cette action sera bientôt développée 🙂'
      })
      commit('PUSH_NOTIFICATION_IN_HISTORY', notification)
    },

    custom ({ state, commit }, customNotification) {
      const notification = this._vm.$vs.notification(customNotification)
      commit('PUSH_NOTIFICATION_IN_HISTORY', notification)
    }

  }
}
