<template>
  <div class="grid">

    <!-- Listage des cards -->
    <vs-row v-if="!loadingState && list.length > 0" justify="space-around" align="center">
      <vs-col v-for="item in list" :key="item.id" w="4" sm="6" xs="12">
        <Card :details="item"/>
      </vs-col>
      <!-- Afin de ne pas faire centrer les dernières cards (2 car 3 par lignes) -->
      <vs-col w="4" aria-hidden="true"></vs-col>
      <vs-col w="4" aria-hidden="true"></vs-col>
    </vs-row>

    <!-- En chargement -->
    <vs-row v-else-if="loadingState" class="wrapperLoadingCards">
      <vs-col v-for="number in cardsPerPage" :key="number" w="4" sm="6" xs="12">
        <Card :loading="true"/>
      </vs-col>
      <!-- Afin de ne pas faire centrer les dernières cards (2 car 3 par lignes) -->
      <vs-col w="4" aria-hidden="true"></vs-col>
      <vs-col w="4" aria-hidden="true"></vs-col>
    </vs-row>

    <!-- Aucune card -->
    <div v-else class="center wrapperNoCards">            
      <h1>Aucune card pour le moment 🤷‍♂️</h1>
      <vs-button size="xl" @click="$router.push({ name: 'Home' })">
        <i class="bx bxs-home"></i> <span>Revenir à l'accueil</span>
      </vs-button>
    </div>

  </div>
</template>

<script>
  import Card from '@/components/Card.vue'

  export default {
    name: 'CardsList',
    components: {
      Card,
    },
    props: {
      loadingState: {
        type: Boolean,
        default: false
      },
      cardsPerPage: {
        type: Number,
        default: 6
      }
    },
    computed: {      
      list () {
        return this.$store.state.card.cards
      }
    }
  }
</script>

<style lang="scss" scoped>

.grid {
  width: 100%;
  box-sizing: border-box;
  padding: 0 2.5%;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -1000px 0
  }
  100%{
    background-position: 1100px 0
  }
}

.wrapperLoadingCards {
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #283149 8%, #f73858c0 18%, #283149 33%);
  background-size: 2200px 30px;
  position: relative;
}

.wrapperNoCards {
  h1 {
    width: 100%;
    text-align: center;
  }
}

</style>