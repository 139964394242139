import axios from 'axios'
import { query } from 'faunadb'
const q = query

const apiClient = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8787/users/' : 'https://cards-workers.devtheory.workers.dev/users/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  call (path, fields) {
    return apiClient.post(path, fields)
  },
  logout (token) {
    return apiClient.post('/logout', { token })
  },
  forgot (email) {
    return apiClient.post('/forgot', { email })
  },
  reset (code, password, token) {
    return apiClient.post('/reset', { code, password, token })
  },
  async applaud ({ cardID, client }) {
    const createApplaudReq = await client.query(
      q.Create(
        q.Collection('Applauses'),
        {
          data: {
            cardRef: q.Ref(q.Collection('Cards'), cardID),
            userRef: q.CurrentIdentity(),
            createdTime: q.Now(),
            counted: false,
            quantity: 1
          }
        }
      )
    )
    const newApplauseID = createApplaudReq?.ref?.value?.id
    return apiClient.post('/applaud', { applauseID: newApplauseID })
  }
}
