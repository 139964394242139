<template>
  <transition appear :name="transitionName">
    <div :class="{ loadingCard: loading }">

      <vs-card @click="openModal">
        <template v-if="!loading" #title>
          <h3>{{ details.title }}</h3>
        </template>      
        <template v-if="!loading" #img>
          <div class="containerLogoSvg">
            <img :src="`/logos/${details.category}.svg`" :alt="`Logo de ${details.category}`">
          </div>
        </template>
        <template #text>
          <template v-if="loading">
            <div class="top"></div>
            <div class="right"></div>
            <div class="left"></div>
            <div class="bottom"></div>
          </template>
          <p v-else></p>
        </template>
        <template v-if="!loading" #interactions>
          <vs-button primary icon @click.stop="bookmarkCard">
            <i class='bx bx-bookmark'></i>
          </vs-button>
          <vs-button class="btn-stats" primary shadow>
            <PartyPopper class="applaudIcon"/>
            <span class="span">
              {{ details.applauses }}
            </span>
          </vs-button>
        </template>
      </vs-card>

    </div>        
  </transition>
</template>

<script>
  import PartyPopper from '@/components/icons/PartyPopper.vue'

  export default {
    name: 'Card',
    components: {
      PartyPopper,
    },
    props: {
      details: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      imgSource() {
        return '/logos/' + this.details.category + '.svg'
      },
      transitionName() {
        return !this.loading ? "fade" : ''
      }
    },
    methods: {
      openModal() {
        if (this.details) {
          this.$router.push({ query: Object.assign({}, this.$route.query, { card: this.details.id }) })
          this.$store.dispatch('card/openModal', { id: this.details.id })
        }
      },
      bookmarkCard(event) {
        if (event.target.classList.contains('bx-bookmark')) {
          this.$store.dispatch('notification/soon')
          event.target.classList.remove('bx-bookmark')
          event.target.classList.add('bxs-bookmark')
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.btn-stats {
  .applaudIcon {
    width: 16px;
    height: 18px;
    fill: #000;
  }
  span {
    padding-left: 5px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>

<style lang="scss">

.vs-card { 
  margin: 25px auto; 
  max-width: 85% !important;
  -webkit-transition: transform .25s ease !important;
  transition: transform .25s ease !important;

  .vs-card__text { 
    padding: 10px 15px 15px;
  }
  .vs-card__img {
    height: 0;
    padding-bottom: 50%;
    background-color: #000;
    .containerLogoSvg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 25%;
      border-radius: 0;
    }
  }
  .vs-card__title { 
    height: 20px;
    padding: 0;
    h3 { 
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 20px;
      font-size: 18px; 
    }
  }

}

.loadingCard .vs-card {
  border-radius: 20px;
  padding-top: 42.5%;
  padding-bottom: 45px;
  background: transparent;
  box-shadow: 0 0 0 20px #283149;
  position: relative;
  cursor: default;
  &:hover {
    -webkit-box-shadow: 0 0 0 20px #283149 !important;
    box-shadow: 0 0 0 20px #283149 !important;
    -webkit-transform: none !important;
    transform: none !important;
  }
  .vs-card__text { padding: 0; }
  .top {
    position: absolute;
    background-color: #283149;
    top: -25px;
    right: -9%;
    width: 118%;
    height: 25px;
  }
  .right {
    position: absolute;
    background-color: #283149;
    bottom: -25px;
    right: -9%;
    width: 9%;
    height: 100%;
    padding: 25px 0;
  }
  .bottom {
    position: absolute;
    background-color: #283149;
    bottom: -25px;
    right: -9%;
    width: 118%;
    height: 25px;
  }
  .left { // TODO
    position: absolute;
    background-color: #283149;
    bottom: -25px;
    left: -9%;
    width: 9%;
    height: 100%;
    padding: 25px 0;
  }
}

</style>