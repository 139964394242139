<template>
  <div id="app">

    <div id="nav">
      <template>
        <div class="hidden">
          <vs-sidebar
            fixed
            reduce
            background="dark"
            textWhite
            v-model="$route.name"
            open
            >

            <template #logo>
              <router-link to="/about" style="text-align: center">
                <img src="@/assets/logo.png" alt="Logo">
              </router-link>
            </template>

            <vs-sidebar-item data-active-on-route="/" to="/">
              <template #icon>
                <i class='bx bx-home'></i>
              </template>
              Accueil
            </vs-sidebar-item>

            <vs-sidebar-item data-active-on-route="/category/javascript" to="/category/javascript">
              <template #icon>
                <i class='bx bxl-javascript'></i>
              </template>
              JavaScript
            </vs-sidebar-item>

            <vs-sidebar-item  data-active-on-route="/category/node" to="/category/node">
              <template #icon>
                <i class='bx bxl-nodejs'></i>
              </template>
              Nodejs
            </vs-sidebar-item>

            <vs-sidebar-item data-active-on-route="/category/react" to="/category/react">
              <template #icon>
                <i class='bx bxl-react'></i>
              </template>
              React
            </vs-sidebar-item>

            <vs-sidebar-item data-active-on-route="/category/vue" to="/category/vue">
              <template #icon>
                <i class='bx bxl-vuejs'></i>
              </template>
              Vuejs
            </vs-sidebar-item>

            <vs-sidebar-item data-active-on-route="/category/angular" to="/category/angular">
              <template #icon>
                <i class='bx bxl-angular'></i>
              </template>
              Angular
            </vs-sidebar-item>

            <vs-sidebar-item data-active-on-route="/about" to="/about" :style="{marginTop: 'auto', marginBottom: '0'}">
              <template #icon>
                <i class='bx bx-help-circle'></i>
              </template>            
              À propos
            </vs-sidebar-item>

            <template #footer>

              <vs-sidebar-item ref="btnAccount">
                <template #icon>
                  <vs-avatar color="#fff">
                    <i class='bx bx-user'></i>
                  </vs-avatar>
                </template>
                Mon compte
              </vs-sidebar-item>

              <!-- <vs-row justify="space-between">
                <vs-avatar color="#fff">
                  <i class='bx bx-user'></i>
                </vs-avatar>
              </vs-row> -->

            </template>

          </vs-sidebar>

        </div>
      </template>
    </div>

    <router-view/>

    <CardDetails />
    <Login />
    <Register />
    <PasswordForgot />
    <PasswordReset />
    <UserProfile />

  </div>
</template>

<script>
import CardDetails from '@/components/CardDetails.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import PasswordForgot from '@/components/PasswordForgot.vue'
import PasswordReset from '@/components/PasswordReset.vue'
import UserProfile from '@/components/UserProfile.vue'

export default {
  data() {
    return {
      sidebarItemsActiveOnRoute: []
    }
  },
  components: {
    CardDetails,
    Login,
    Register,
    PasswordForgot,
    PasswordReset,
    UserProfile
  },
  created() {    
    this.$store.dispatch('user/setStateUserData')
  },
  mounted() {
    // Mise en place du système d'icône active dans la sidebar
    this.sidebarItemsActiveOnRoute = document.body.querySelectorAll('.vs-sidebar__item[data-active-on-route]')
    this.updateActiveItem(this.$route)

    // Ouverture d'un modal au click du bouton "Mon Compte"
    this.$refs.btnAccount.$el.addEventListener('click', async () => {
      let userModalToOpen
      if (await this.$store.getters['user/isAuthenticated']) {
        userModalToOpen = 'userprofile'
      } else {
        userModalToOpen = 'login'
      }
      this.$store.dispatch('user/openModal', userModalToOpen)
    })

    // Ouverture du modal de réinitialisation du mot de passe si besoin
    if (this.$route.query.modal === 'passwordreset') {
      this.$store.dispatch('user/openModal', 'passwordreset')
    }
  },
  methods: {
    // Rendre actif les liens des catégories de la sidebar
    updateActiveItem(to) {
      for (let i = 0; i < this.sidebarItemsActiveOnRoute.length; i++) {
        const link = this.sidebarItemsActiveOnRoute[i]
        if (link.getAttribute('data-active-on-route') == to.path) {
          link.classList.add('active')
        } else {          
          link.classList.remove('active')
        }
      }
    }
  },
  watch: {
    // Rendre actif les liens des catégories de la sidebar
    $route(to, from) {
      this.updateActiveItem(to)
    }
  }
}
</script>

<style lang="scss">

:root {
  --vs-shadow-opacity: .3 !important;
}

body {
  background-color: #283149;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

#app {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0 30px 50px;
}

.center { 
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
}

// Progress bar
#nprogress {
  .bar { background: #F73859 !important; }
  .peg { box-shadow: 0 0 10px #F73859, 0 0 5px #F73859 !important; }
  .spinner-icon {
    border-top-color: #F73859 !important;
    border-left-color: #F73859 !important;
  }
}

// Vuesax
.vs-sidebar-content {
  -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,var(--vs-shadow-opacity));
  box-shadow: 0 0 25px 0 rgba(0,0,0,var(--vs-shadow-opacity));
  .vs-sidebar__logo img {
    max-height: none !important;
  }
  .vs-sidebar__footer { 
    padding: 10px 0 30px !important;
    .vs-avatar-content {
      width: 40px;
      height: 40px;
      opacity: .5;
    }
    .vs-sidebar__item:hover .vs-avatar-content {
      opacity: 0.8;
    }
  }
} 
.vs-dialog-content {
  background: rgba(0, 0, 0, 0.75) !important;
}
.vs-dialog-content.userProfileModal {
  .vs-dialog { border-radius: 10px !important; }
  .vs-dialog__content { 
    max-height: calc(100vh - 200px) !important;
    margin: 0 !important;
    padding: 1rem 2rem !important; 
  }
}
.vs-button {
  i { 
    position: relative; 
    top: -1.5px; 
  }
  i ~ span {
    padding-left: 5px;
  }
}
.vs-dialog__loading {
  &:after, &:before { border-radius: 50% !important; }
}
.cardContent {
  line-height: 1.75 !important;
  font-size: 16.5px;
}
</style>
