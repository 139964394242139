<template>
  <vs-dialog
  v-model="active"
  @close="closeModal"
  not-close
  :loading="loading"
  class="userProfileModal"
  >    
    <template #header>
      <h4 class="not-margin">
        Mot de passe <b>oublié</b>
      </h4>
    </template>

    <form v-if="!code" class="con-form" @submit.prevent="submit">

      <vs-input type="email" v-model="fields.email" placeholder="Email" autocomplete="email">
        <template #icon>
          @
        </template>
      </vs-input>

      <vs-alert v-if="errorsSentences.length > 0" :key="errorsCodes.join()">

        <template #title>
          {{ errorsSentences.length >= 2 ? 'Erreurs' : 'Erreur' }} de connexion
        </template>
        
        <ul v-if="errorsSentences.length >= 2" style="margin: 0">
          <li v-for="(sentence, index) in errorsSentences" :key="index">{{ sentence }}</li>
        </ul>

        <span v-else>{{ errorsSentences[0] }} </span>

      </vs-alert>

    </form>

    <article v-else class="contentUserModal">
      <p>Si l'email indiqué est bien lié à un compte DevTheory, alors un mail a été envoyé pour réinitialiser le mot de passe.</p>
      <p>Pour être sûr que vous soyez bien celui qui modifie le mot de passe, vous devrez indiquer ce code lorsque vous arrivez sur la page indiquée par mail :</p>
      <h3 class="codeForgotPassword">{{ code }}</h3>
    </article>

    <template #footer>
      <div v-if="!code" class="footer-dialog">
        <vs-button block @click="submit">
          Envoyer le mail de réinitialisation
        </vs-button>

        <div class="new">
          Vous avez votre mot de passe ?<a href="#" @click="changeUserModal('login')">Connectez-vous ici</a>
        </div>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
  import { ModalWithUserForm } from '@/mixins/ModalWithUserForm.js'
  import { validate as validateEmail } from 'email-validator'

  export default {
    name: 'Login',
    mixins: [ModalWithUserForm],
    data() {
      return {
        fields: {
          email: ''
        },
        code: null
      }
    },
    watch: {
      currentOpenedUserModal(newVal) {
        this.active = (newVal === 'passwordforgot')
      }
    },
    methods: {
      async submit () {
        // Vérifications des valeurs
        this.errorsCodes = []
        this.checkFields()
        // Envoie de la demande de réinitialisation au serveur
        if (this.errorsCodes.length === 0) {
          this.loading = true
          const [status, data] = await this.$store.dispatch('user/forgot', {
            email: this.fields.email
          })
          this.loading = false
          if (status === 'success') {
            this.code = data
          } else {
            this.errorsCodes.push(data)
          }
        }
      },
      checkFields() {
        if (this.fields.email == '') {
          this.errorsCodes.push('empty_field')
        } else if (!validateEmail(this.fields.email)) {
          this.errorsCodes.push('wrong_email_format')
        }
      },
      resetFields() {
        this.fields.email = ''
      }
    }
  }
</script>

<style lang="scss">
.not-margin {
	margin: 0px;
	font-weight: normal;
	padding: 10px;
}

.con-form {
	width: 100%;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      font-size: 0.8rem;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }

  .vs-checkbox-label {
    font-size: 0.8rem;
  }

  .vs-input-content {
    margin: 10px 0px;
    width: calc(100%);
    .vs-input {
      width: 100% !important;
    }
  }

}

.contentUserModal {
  max-width: 500px; 
  line-height: 1.75;
}
.codeForgotPassword {
  text-align: center; 
  letter-spacing: 5px;
  font-size: 2em;
  color: #F73859;
}

.footer-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: calc(100%);

  .new {
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-size: 0.7rem;
    a {
      color: getColor('primary') !important;
      margin-left: 6px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .vs-button {
    margin: 0px;
  }


}
</style>