<template>
  <vs-dialog
  v-model="active"
  @close="closeModal"
  not-close
  :loading="loading"
  class="userProfileModal"
  >    
    <template #header>
      <h4 class="not-margin">
        Compte: {{ userData.email }}
      </h4>
    </template>

    <vs-alert color="dark">
      <template #title>
        Vous êtes connecté !
      </template>
      <article class="alertContent">
        En étant connecté vous pouvez féliciter des cards ou bien les bookmarker pour les lire plus tard.
      </article>
    </vs-alert>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block @click="submit">
          Se déconnecter
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>

  export default {
    name: 'UserProfile',
    data() {
      return {
        active: false,        
        loading: false
      }
    },
    computed: {
      userData() {
        return {
          email: this.$store.state.user.email,
          token: this.$store.state.user.token
        }
      },
      isAuthenticated() {
        return this.$store.getters['user/isAuthenticated']
      },
      currentOpenedUserModal () {
        return this.$store.state.user.currentOpenedModal
      },
    },
    watch: {
      currentOpenedUserModal(newVal) {
        this.active = (newVal === 'userprofile' && this.isAuthenticated) ? true : false
      }
    },
    methods: {
      closeModal () {
        this.$store.dispatch('user/closeModal')
      },
      async submit () {
        this.loading = true
        const error = await this.$store.dispatch('user/logout', { token: this.userData.token })
        this.loading = false
        if (typeof error === 'string') {
          let errorSentence = this.$store.state.errors[error] || `Erreur inconnue: ${error}`
          this.$store.dispatch('notification/error', errorSentence)
        } else {
          this.$store.dispatch('notification/success', 'Vous n\'êtes plus connecté !')
          this.closeModal()
        }
      }
    }
  }
</script>

<style lang="scss">
.not-margin {
	margin: 0px;
	font-weight: normal;
	padding: 10px;
}

.alertContent { 
  max-width: 300px; 
  line-height: 1.75;
}

.footer-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: calc(100%);

  .new {
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-size: 0.7rem;
    a {
      color: getColor('primary') !important;
      margin-left: 6px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  

}
.userProfileModal .footer-dialog .vs-button { margin: 10px 0 15px; }
</style>