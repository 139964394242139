export const ViewsWithCardsList = {
  data () {
    return {
      page: 1,
      perPage: 6,
      areCardsLoading: true
    }
  },
  created () {
    this.$store.dispatch('card/resetAll')
    if (this.faunaClient) { // Exécution quand on arrive sur cette page en venant d'une autre
      if (this.$route.query.card) {
        this.$store.dispatch('card/openModal', { id: this.$route.query.card })
      }
      this.dispatchFetchCards()
    }
  },
  watch: {
    // Si c'est la 1ère page, récupération des cards dès que Fauna est en place
    faunaClient (newValue) {
      if (newValue !== null) {
        this.dispatchFetchCards()
        if (this.$route.query.card) {
          this.$store.dispatch('card/openModal', { id: this.$route.query.card })
        }
      }
    }
  },
  computed: {
    isPossibleToGoAfter () {
      return this.$store.getters['card/areThereCardsAfter']
    },
    isPossibleToGoBefore () {
      return (this.page > 1)
    },
    faunaClient () {
      return this.$store.state.fauna.client
    }
  },
  methods: {
    dispatchFetchCards (direction) {
      return this.$store.dispatch('card/fetchAll', {
        perPage: this.perPage,
        direction
      }).then(() => {
        this.areCardsLoading = false
        if (direction) {
          this.page = (direction === 'after') ? this.page + 1 : this.page - 1
        } else {
          this.page = 1
        }
      })
    },
    goToPreviousPage () {
      if (this.isPossibleToGoBefore) {
        this.areCardsLoading = true
        if (this.page === 2) {
          this.dispatchFetchCards()
        } else {
          this.dispatchFetchCards('before')
        }
      }
    },
    goToNextPage () {
      if (this.isPossibleToGoAfter) {
        this.areCardsLoading = true
        this.dispatchFetchCards('after')
      }
    }
  }
}
